import { LoginOutlined, PoweroffOutlined, UserAddOutlined } from "@ant-design/icons";

import { Avatar, Badge, Layout, Menu, MenuProps, Popover, Tabs } from "antd";
import "antd/dist/reset.css";
import { DisplayUser } from "components/Displays/DisplayUser";
import { DisplayNotifications } from "features/notification/components/DisplayNotifications";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { BellIcon } from "icons/Icons";
import { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useLogoutUserMutation } from "store/auth/api/authapi";
import { logOutThunk } from "store/auth/slice/authSlice";

import { useWeb3AuthContext } from "web3Auth/components/Web3AuthContextProvider";
import { getItem } from "../utils/menuUtils";
import { colorToTheme, themeBell, themeLogos, themeStyles } from "../utils/themeUtils";

const { TabPane } = Tabs;
const { Header } = Layout;

const NavBar = () => {
  const [notiCount, setNotiCount] = useState(0);
  const { userid, token, userpreferences } = useAppSelector((state) => state.auth);
  const navbarColorObj = userpreferences?.find((pref) => pref.type === "Color" && pref.key === "navbar");
  const navbarColor = navbarColorObj?.value ?? "#ffffff";
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const theme = colorToTheme[navbarColor ?? "#ffffff"] ?? "light";

  const [logoutUser] = useLogoutUserMutation();

  const { logout } = useWeb3AuthContext();

  //notificaiton starts::
  //Step2: Listen for notification
  // onMessageListener().then((payload) => {
  //   // console.log("----------------:-------notificaiton received", payload);
  //   /**
  //    * show notification
  //    */
  //   setNotiCount(notiCount + 1);

  //   const message = JSON.parse(payload.data?.message ?? "{}");

  //   openNotification(message);
  // });
  // .catch((err) => console.log("failed: ", err));

  //Step3: Open notification
  // const openNotification = (message: { title: string; info: string; msgtype: "INFO" | "WARNING" | "PROHIBITION" }) => {
  //   const msg = {
  //     top: 65,
  //     message: message.title,
  //     description: message.info,
  //     onClick: () => {
  //       setNotiCount(notiCount - 1);
  //       //TODO- callback to update the status to viewed if needed
  //       // console.log("Notification Clicked!");
  //     },
  //   };

  //   if (message.msgtype === "INFO") {
  //     notification.info(msg);
  //   } else if (message.msgtype === "WARNING") {
  //     notification.warning(msg);
  //   } else if (message.msgtype === "PROHIBITION") {
  //     notification.error(msg);
  //   }
  // };
  //notificaiotn ends::

  const handleFuelFWDLogoClick = () => {
    if (location.pathname === "/dashboard") {
      window.location.reload();
    } else {
      history.push("/dashboard");
    }
  };

  const allMenuItems = [
    getItem(<Link to="/dashboard">Dashboard</Link>, "dashboard", null, "l1_menu_dashboard", false, null),
    // getItem(<Link to="/twins">Passport</Link>, "twins", null, "l1_menu_product", false, null),
    // getItem(<Link to="/tandt">Track and Trace</Link>, "tandt", <SearchOutlined />, "l1_menu_tandt"),

    getItem(<Link to="/dm">Document Module</Link>, "dm", null, "l1_menu_dm", false, null),

    getItem("Administration", "admin", null, "l1_menu_admin", false, [
      getItem(<Link to="/org">Organization</Link>, "org", null, "l2_menu_org"),
      // getItem(<Link to="/facilities">Facilities</Link>, "facility", null, "l2_menu_facility"),
      // getItem(<Link to="/certificates">Certificates</Link>, "certificates", null, "l2_menu_certificate"),
      getItem(<></>, "divider", null, "l1_menu_admin", true),
      getItem(<Link to="/user">Users</Link>, "user", null, "l2_menu_user"),
      getItem(<Link to="/role">Roles</Link>, "role", null, "l2_menu_role"),
      getItem(<Link to="/priv">Privileges</Link>, "priv", null, "l2_menu_priv"),
      getItem(
        <Link to="/certificationscopes">Certification Scopes</Link>,
        "certificationscopes",
        null,
        "l2_menu_certification_scope"
      ),
      //   getItem(<Link to="/twins">Onchain-Authorization</Link>, "onchain", null, "l2_menu_user", []),
    ]),
    getItem("Configuration", "config", null, "l1_menu_config", false, [
      getItem(<Link to="/unit">Units</Link>, "unit", null, "l2_menu_units"),
      getItem(<Link to="/product">Products</Link>, "product", null, "l2_menu_products"),
      // getItem(<Link to="/tra">Transformations</Link>, "tra", null, "l2_menu_transformation"),
      getItem(<Link to="/doctypes">Document Types</Link>, "doctypes", null, "l2_menu_doctypes"),
      // getItem(<Link to="/logisticstates">Logistic States</Link>, "logistic_states", null, "l2_menu_logistic_states"),
      getItem(<></>, "divider", null, "l1_menu_config", true),
      getItem(<Link to="/attribute">Attributes</Link>, "attribute", null, "l2_menu_identifier"),
      getItem(<Link to="/identifier">Identifiers</Link>, "identifier", null, "l2_menu_identifier"),
      getItem(<Link to="/composedevents">Events</Link>, "composed_events", null, "l2_menu_composed_event"),
      getItem(<Link to="/partners">Partners</Link>, "partners", null, "l2_menu_partners"),
      getItem(<Link to="/facilities">Facilities</Link>, "facility", null, "l2_menu_facility"),
      getItem(<Link to="/certificates">Certificates</Link>, "certificates", null, "l2_menu_certificate"),
    ]),
    getItem("Document Vault", "documentvault", null, "l1_menu_document", false, [
      getItem(<Link to="/document">Documents</Link>, "document", null, "l1_menu_document"),
    ]),
    getItem(<Link to="/report">Report</Link>, "report", null, "l1_menu_document"),
    getItem(<Link to="/login">Login</Link>, "login", <LoginOutlined />, "na", null, { marginLeft: "auto" }),
    getItem(<Link to="/register">Register</Link>, "register", <UserAddOutlined />, "na", null),

    getItem(
      null,
      "notification",
      <Badge size="default" count={notiCount} style={{ background: "transparent" }}>
        <Popover
          // defaultActiveKey="notifications"
          placement="bottomRight"
          content={
            <Tabs
              style={{
                width: "400px",
              }}
            >
              <TabPane tab="Notifications" key="notifications">
                <DisplayNotifications userid={userid} type="APP"></DisplayNotifications>
              </TabPane>
              <TabPane tab="Messages" key="messages"></TabPane>
            </Tabs>
          }
          trigger="click"
        >
          <Avatar style={{ backgroundColor: "transparent" }} size={30} icon={<BellIcon src={themeBell[theme]} />} />
        </Popover>
      </Badge>,
      "l1_default",
      false,
      [],
      { marginLeft: "auto" }
    ),
    getItem(
      <DisplayUser userid={userid} avatar isDisplayEmail={true} />,
      "userprofile",
      null,
      "l1_default",
      false,
      [
        getItem(<Link to="/profile">Profile</Link>, "profile", null, "l1_default"),
        getItem(<Link to="/pref">Preferences</Link>, "preferences", null, "l1_default"),
        getItem("Logout", "logout", <PoweroffOutlined />, "l1_default"),
      ],
      {
        padding: 0,
        marginLeft: 16,
      }
    ),
  ];

  // if (!isAddressesSame(userbaddress, AddressZero)) {
  //   allMenuItems.splice(
  //     allMenuItems.length - 2,
  //     0,
  //     getItem(
  //       null,
  //       "bcwallet",
  //       <div>
  //         <div
  //           style={{ display: "flex", alignItems: "center", gap: 5 }}
  //           onClick={() => openConnectKitModal(!isConnetModalOpen)}
  //         >
  //           <Avatar
  //             style={{ background: "transparent" }}
  //             size={30}
  //             icon={
  //               <WalletIcon
  //                 src={isConnected ? themeWallet[theme].connected.icon : themeWallet[theme].notConnected.icon}
  //               />
  //             }
  //           />
  //           <div style={isConnected ? themeWallet[theme].connected.style : themeWallet[theme].notConnected.style}>
  //             {isConnected
  //               ? address?.substring(0, 6) + "..." + address?.substring(address?.length - 4, address.length)
  //               : "Connect"}
  //           </div>
  //         </div>
  //       </div>,
  //       "l1_default",
  //       false,
  //       [],
  //       { marginLeft: "auto" }
  //     )
  //   );
  // }

  //get the roles
  const userRoles = useAppSelector((state) => state.auth.roles);

  const menuPrivIds = userRoles.reduce(
    (acc: string[], curr) => {
      if (curr.type === "Offchain") {
        return [...acc, ...curr.privs.filter((priv) => priv.type === "Menu").map((priv) => priv.name)];
      }
      return acc;
    },
    ["l1_default"]
  );

  const menuItemsToLoad = token
    ? allMenuItems
        .filter((item) => menuPrivIds.includes(item.privid))
        .map(({ menuItem }: any) => {
          return menuItem.children
            ? {
                ...menuItem,
                children: menuItem.children
                  .filter((child: any) => menuPrivIds.includes(child.privid))
                  .map((child: any) => child.menuItem),
              }
            : menuItem;
        })
    : allMenuItems.filter((item) => item.privid === "na");
  const onClick: MenuProps["onClick"] = async (e) => {
    setCurrent(e.key);
    if (e.key === "logout") {
      await logoutUser();
      await logout();
      console.log("logout");
      dispatch(logOutThunk());
      history.push("/login");
    }
  };

  const currentTab = menuItemsToLoad.filter(({ key, children }: any) => {
    return location.pathname.includes(key) || children?.some(({ key }: any) => location.pathname.includes(key));
  });

  const [current, setCurrent] = useState(currentTab[0]?.key ?? "");

  if (location.pathname === "/login" || location.pathname === "/register" || location.pathname === "/") return null;

  return (
    <Header
      style={{
        ...themeStyles[theme],
        position: "fixed",
        zIndex: 150,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "0 96px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
      }}
    >
      <div style={{ maxWidth: "1444px", width: "100%" }}>
        <div
          className="logo"
          style={{ marginTop: 0, marginLeft: 0, cursor: "pointer" }}
          onClick={handleFuelFWDLogoClick}
        >
          <img src={themeLogos[theme]} alt="logo" />
        </div>
        <Menu
          mode="horizontal"
          onClick={onClick}
          selectedKeys={[current]}
          items={menuItemsToLoad}
          theme={theme}
          style={{ ...themeStyles[theme] }}
        />
      </div>
    </Header>
  );
};

export default NavBar;
