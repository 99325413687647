import { Result } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const NotFound = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    return () => setIsLoading(false);
  }, [location.pathname]);

  if (isLoading) {
    return null;
  }

  return <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;
};

export default NotFound;
