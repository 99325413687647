import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  PortfolioTotalsQuery,
  PortfolioTotalsResponse,
  TradeTwinsQuery,
  TradedTwinsResponse,
  TwinInventoryResponse,
  TwinProcessingResponse,
  TwinsInventoryQuery,
  TwinsFacilityChangeQuery,
  TwinsInflowResponse,
  TwinsOutflowResponse,
  TwinsProcessingQuery,
} from "../types/types";

export const asApi = createApi({
  reducerPath: "asApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/as/hydrotwin/" }),
  tagTypes: ["Aggregation"],
  endpoints: (build) => ({
    getAllTwinsReport: build.query<PortfolioTotalsResponse[], PortfolioTotalsQuery>({
      query(body) {
        return {
          url: "all_twins_report",
          method: "POST",
          body,
        };
      },
      providesTags: ["Aggregation"],
    }),
    getTradedTwins: build.query<TradedTwinsResponse[], TradeTwinsQuery>({
      query(body) {
        return {
          url: "traded",
          method: "POST",
          body,
        };
      },
    }),
    getTwinsStartInventory: build.query<TwinInventoryResponse[], TwinsInventoryQuery>({
      query(body) {
        return {
          url: "inventory",
          method: "POST",
          body,
        };
      },
    }),
    getProcessingTwins: build.query<TwinProcessingResponse[], TwinsProcessingQuery>({
      query(body) {
        return {
          url: "processing",
          method: "POST",
          body,
        };
      },
    }),
    getInflowTwins: build.query<TwinsInflowResponse[], TwinsFacilityChangeQuery>({
      query(body) {
        return {
          url: "incoming",
          method: "POST",
          body,
        };
      },
    }),
    getOutflowTwins: build.query<TwinsOutflowResponse[], TwinsFacilityChangeQuery>({
      query(body) {
        return {
          url: "outgoing",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetAllTwinsReportQuery,
  useGetInflowTwinsQuery,
  useGetOutflowTwinsQuery,
  useGetProcessingTwinsQuery,
  useGetTradedTwinsQuery,
  useGetTwinsStartInventoryQuery,
} = asApi;
