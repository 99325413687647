import { lazy } from "react";
import type { RouteProps } from "react-router-dom";

const Login = lazy(() => import("features/Auth").then((module) => ({ default: module.Login })));
const Registration = lazy(() => import("features/Auth").then((module) => ({ default: module.Registration })));
const ForgotPassword = lazy(() => import("features/Auth").then((module) => ({ default: module.ForgotPassword })));
const ResetPassword = lazy(() => import("features/Auth").then((module) => ({ default: module.ResetPassword })));

export const publicRoutes: RouteProps[] = [
  {
    path: "/register",
    render: () => <Registration />,
    exact: true,
  },
  {
    path: "/login",
    render: () => <Login />,
    exact: true,
  },
  {
    path: "/forgot-password",
    render: () => <ForgotPassword />,
    exact: true,
  },
  {
    path: "/reset-password/:token",
    render: () => <ResetPassword />,
    exact: true,
  },

  // { path: "/", exact: true, render: () => <Redirect to="/login" /> },
];
