import { AddressZero } from "@ethersproject/constants";
import jwtDecode from "jwt-decode";
import { useRefreshUserMutation } from "store/auth/api/authapi";
import { setAuth } from "store/auth/slice/authSlice";
import { JWTUser, PrivilegeResponse, UserResponse } from "store/auth/types/types";
import { useAppDispatch, useAppSelector } from "./hooks";
import { useGetUserQuery } from "store/auth/api/userapi";
import { useHistory } from "react-router-dom";

export const useAuth = () => {
  const authData = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [refreshToken, { isLoading, isUninitialized, isError }] = useRefreshUserMutation();
  const { data: userData } = useGetUserQuery(authData.userid, { skip: !authData.userid });
  if (authData.token !== "") return { isLoggedIn: true, expired: false };
  const jwtFromStorage = localStorage.getItem("jwt");
  if (jwtFromStorage) {
    const decoded: JWTUser = jwtDecode(jwtFromStorage);

    const expired = decoded.exp < Date.now() / 1000;
    if (expired) {
      if (isUninitialized && !isLoading) {
        try {
          refreshToken().then((res) => {
            if ("error" in res || isError) {
              history.push("/login");
              return { isLoggedIn: false, expired: true };
            } else {
              dispatch(setAuth({ ...authData, token: res.data.jwt }));
            }
          });
        } catch (err) {
          history.push("/login");
          return { isLoggedIn: false, expired: true };
        }
      }
      return { isLoggedIn: true, expired: true };
    } else {
      const user: UserResponse = userData ?? JSON.parse(localStorage.getItem("user") ?? "");
      const authObj = {
        token: jwtFromStorage,
        userid: decoded.userId,
        userbaddress: decoded.baddress === "" ? AddressZero : decoded.baddress.toLowerCase(),
        org: user?.org,
        roles: user?.roles ?? [],
        userpreferences: user?.userpreferences ?? [],
        actionPrivs: new Array<string>(),
      };
      const actionPrivs = new Array<PrivilegeResponse>();
      authObj.roles.forEach((role) => {
        actionPrivs.push(...(role.privs.filter((priv) => priv.type === "Action")?.flat() ?? []));
      });
      authObj.actionPrivs = actionPrivs.map(({ name }) => name);

      dispatch(setAuth(authObj));
      return { isLoggedIn: true, expired: false };
    }
  }
  return { isLoggedIn: false, expired: true };
};
