import React, { memo } from "react";
import { Avatar, Col, Row, Typography } from "antd";
import { isAddress } from "ethers/lib/utils.js";
import { useGetUserQuery, useGetUsersByParamQuery } from "store/auth/api/userapi";
import { Buffer } from "buffer";

type DisplayUserProps = {
  userid: string;
  isDisplayEmail?: boolean;
  avatar?: boolean;
  onlyName?: boolean;
};

export const DisplayUser = memo(
  ({ userid, isDisplayEmail = false, avatar = false, onlyName = false }: DisplayUserProps) => {
    const { data: userByBaddress } = useGetUsersByParamQuery({ baddress: userid }, { skip: !isAddress(userid) });
    const { data: userById } = useGetUserQuery(userid, { skip: isAddress(userid) });
    const data = userById ?? userByBaddress?.userList?.[0];

    if (onlyName) return <>{`${data?.fname} ${data?.lname}`}</>;

    return (
      <div style={{ display: "flex", alignItems: "center", maxHeight: 60 }}>
        {!!avatar && (
          <div style={{ marginRight: 8 }}>
            {data && data.fname && !data?.profilepicture ? (
              <Avatar>{(data?.fname?.at(0) || "U").toUpperCase()}</Avatar>
            ) : (
              <Avatar src={"data:image/png;base64," + Buffer.from(data?.profilepicture ?? [])?.toString("base64")} />
            )}
          </div>
        )}
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography.Text style={{ color: "inherit" }}>
              {data?.fname} {data?.lname}
            </Typography.Text>
            {isDisplayEmail && (
              <Typography.Text style={{ color: "inherit", fontSize: 11 }} type="secondary">
                {data?.email}
              </Typography.Text>
            )}
          </div>
        </div>
      </div>
    );
  }
);
