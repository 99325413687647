import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Passport, PassportConfig, PassportConfigResponse, PassportResponse } from "store/twin/types/types";

export const passportApi = createApi({
  reducerPath: "passportApi",
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: "/ps/ht/passport",
  }),
  tagTypes: ["passport", "passportConfig"],
  endpoints: (build) => ({
    //passport
    addPassport: build.mutation<PassportResponse, Passport>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["passport"],
    }),
    updatePassport: build.mutation<void, Passport & { ID: number }>({
      query: (body) => ({
        url: "/",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["passport"],
    }),
    deletePassport: build.mutation<void, number>({
      query: (passportId) => ({
        url: `/${passportId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["passport"],
    }),
    deletePassportByTwinID: build.mutation<void, number>({
      query: (twinId) => ({
        url: `/twin/${twinId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["passport"],
    }),

    //passportConfig
    getPassportConfigByID: build.query<PassportConfigResponse, number>({
      query: (passportConfigId) => `/config/${passportConfigId}`,
      providesTags: ["passportConfig"],
    }),
    getPassportConfigByComposedEventID: build.query<PassportConfigResponse[], number>({
      query: (composedEventId) => `/config/composed-event/${composedEventId}`,
      providesTags: ["passportConfig"],
    }),
    addPassportConfig: build.mutation<PassportConfigResponse, PassportConfig>({
      query: (body) => ({
        url: "/config",
        method: "POST",
        body,
      }),
      invalidatesTags: ["passportConfig"],
    }),
    updatePassportConfig: build.mutation<void, PassportConfig & { ID: number }>({
      query: (body) => ({
        url: "/config",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["passportConfig"],
    }),
    deletePassportConfig: build.mutation<void, number>({
      query: (passportConfigId) => ({
        url: `/config/${passportConfigId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["passportConfig"],
    }),
    deletePassportConfigByComposedEventID: build.mutation<void, number>({
      query: (composedEventId) => ({
        url: `/config/composed-event/${composedEventId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["passportConfig"],
    }),
  }),
});

export const {
  useAddPassportMutation,
  useDeletePassportMutation,
  useDeletePassportByTwinIDMutation,
  useUpdatePassportMutation,

  useGetPassportConfigByIDQuery,
  useGetPassportConfigByComposedEventIDQuery,
  useLazyGetPassportConfigByComposedEventIDQuery,
  useAddPassportConfigMutation,
  useDeletePassportConfigMutation,
  useDeletePassportConfigByComposedEventIDMutation,
  useUpdatePassportConfigMutation,
} = passportApi;
