import { Flex, Layout, Spin, message } from "antd";
import "antd/dist/reset.css";
import { NavBar } from "features/navBar";
import { useAppSelector } from "hooks/hooks";
import { useAuth } from "hooks/useAuth";
import { Suspense } from "react";
import { Routes } from "routes";
import { FOURLITE_CONFIG } from "config/config";
import "./App.css";

const { Content, Footer } = Layout;

function App() {
  // TODO: use Reselect Reduxt Library to create custom selector
  const userid = useAppSelector((state) => state.auth.userid);

  const { isLoggedIn } = useAuth();

  message.config({
    top: 75,
    duration: 3,
    maxCount: 5,
  });

  if (FOURLITE_CONFIG) {
    const originalFetch = fetch;

    const fourlite = async function (body: object) {
      await originalFetch (FOURLITE_CONFIG!, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify (body),
      });
    }

    window.fetch = async function (req, options) {

      const path = req instanceof Request ? req.url : req;
      let requestBody: any;

      if (req instanceof Request) {
        requestBody = await req.clone().text();
      } else if (typeof req === "string") {
        if (options && options.body) {
            if (typeof options.body === "string") {
                requestBody = options.body;
            } else {
                requestBody = await new Response(options.body).text();
            }
        }
      }

      if (requestBody) {
        try {
          requestBody = JSON.parse (requestBody);
        }
        catch (error) {
          console.log ('Ignore');
        }
      }

      const t = Date.now ();
      const reqId = parseInt ((Math.random () + '').slice (2, 8)).toString (16);

      if (path !== FOURLITE_CONFIG) fourlite ({
        t: Date.now (),
        logs: [
          {
            reqId,
            method: options ? options.method : 'GET',
            path,
            reqHeaders: req instanceof Request ? req.headers : (options ? options.headers : undefined),
            reqBody: requestBody,
          }
        ],
        tags: ['UI'],
      });

      const res = await originalFetch (req, options);

      const clonedResponse = res.clone ();
      let responseBody = await clonedResponse.text ();
      if (responseBody) {
        try {
          responseBody = JSON.parse (responseBody);
        }
        catch (error) {
          console.log ('Ignore');
        }
      }

      if (path !== FOURLITE_CONFIG) fourlite ({
        t: Date.now (),
        logs: [
          {
            reqId,
            method: options ? options.method : 'GET',
            path,
            code: res.status,
            resHeaders: res.headers,
            resBody: responseBody,
            duration: Date.now () - t,
          }
        ],
        tags: ['UI'],
      });
      return res;
    }
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {isLoggedIn ? <NavBar key={userid} /> : null}
      <Layout>
        <Content
          className="site-layout"
          style={{
            padding: "0 72px",
            marginTop: 58,
          }}
        >
          <div
            style={{
              padding: 24,
              height: "100%",
              margin: "auto",
            }}
          >
            <Suspense
              fallback={
                <Flex align="center" gap="middle" style={{ justifyContent: "center", height: "34rem" }}>
                  <Spin spinning={true} size="large" />
                </Flex>
              }
            >
              <Routes isLoggedIn={isLoggedIn} />
            </Suspense>
          </div>
        </Content>
      </Layout>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        FuelFWD ©{new Date().getFullYear()} by <a href="https://www.docklab.nl">DockLab</a>
      </Footer>
    </Layout>
  );
}

export default App;
