import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, List, Skeleton } from "antd";

import { useGetNotificationsByParamQuery } from "store/notification/api/notificationapi";

// TODO: check if this is still needed
export const DisplayNotifications = ({ userid, type }: { userid: string; type: string }) => {
  const params = {
    subscriberid: userid,
    notificationtype: type,
  };

  const { data, isLoading } = useGetNotificationsByParamQuery(params);

  if (!isLoading) {
    console.log(data);
  }

  return (
    <div>
      <List
        className="demo-loadmore-list"
        loading={isLoading}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(notification: any) => (
          <List.Item
            actions={
              notification?.message?.actiontype === "ACTIONABLE" && notification?.message?.actions?.length > 0
                ? notification?.message?.actions?.map((action: any, index: number) => (
                    <Action key={index} action={action}></Action>
                  ))
                : []
            }
          >
            <Skeleton avatar title={false} loading={isLoading} active>
              <List.Item.Meta
                avatar={
                  notification?.message?.msgtype === "INFO" ? (
                    <InfoCircleOutlined style={{ fontSize: "20px", color: "#08c" }} />
                  ) : notification?.message?.msgtype === "WARNING" ? (
                    <InfoCircleOutlined style={{ fontSize: "20px", color: "#FFA500" }} />
                  ) : notification?.message?.msgtype === "PROHIBITION" ? (
                    <InfoCircleOutlined style={{ fontSize: "20px", color: "#ff0000" }} />
                  ) : (
                    <InfoCircleOutlined style={{ fontSize: "20px" }} />
                  )
                }
                title={notification?.message?.title}
                description={notification?.message?.info}
              />
            </Skeleton>
          </List.Item>
        )}
      />
    </div>
  );
};

const Action = ({ action }: { action: { name?: string } }) => {
  console.log("action--------", action);
  //implement - custom action
  return <>{action?.name === "Review RA" ? <DealAction actionName={action?.name}></DealAction> : null}</>;
};

const DealAction = ({ actionName }: { actionName: string }) => {
  return (
    <Button type="link" size="small" key="list-loadmore-edit" onClick={() => undefined}>
      {actionName}
    </Button>
  );
};
