import { AddressZero } from "@ethersproject/constants";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { notificationApi } from "store/notification/api/notificationapi";
import type { AppDispatch, RootState } from "store/store";
import { twinApi } from "store/twin/api/twinapi";
import { vaultApi } from "store/vault/api/vaultapi";
import { authApi } from "../api/authapi";
import type { JWTUser, Preference, PrivilegeResponse, RoleResponse, UserState } from "../types/types";
import { passportApi } from "store/twin/api/hydrotwin/api/passportapi";
import { emissionApi } from "store/twin/api/hydrotwin/api/emissionapi";
import { assetApi } from "store/twin/api/hydrotwin/api/assetapi";
import { txPassportOutputApi } from "store/twin/api/hydrotwin/api/txpassporteoutputapi";

const initialState: UserState = {
  token: "",
  userid: "",
  userbaddress: "",
  org: undefined,
  roles: [],
  userpreferences: [],
  actionPrivs: [],
};

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();

export const logOutThunk = createAppAsyncThunk<void, void>("auth/logout", async (_, { dispatch }) => {
  dispatch(authApi.util.resetApiState());
  dispatch(vaultApi.util.resetApiState());
  dispatch(notificationApi.util.resetApiState());
  dispatch(twinApi.util.resetApiState());
  dispatch(passportApi.util.resetApiState());
  dispatch(emissionApi.util.resetApiState());
  dispatch(txPassportOutputApi.util.resetApiState());
  dispatch(assetApi.util.resetApiState());
  localStorage.removeItem("jwt");
  localStorage.removeItem("user");
  return;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<UserState>) => {
      state.token = action.payload.token;
      state.userid = action.payload.userid;
      state.userbaddress = action.payload.userbaddress;
      state.org = action.payload.org;
      state.roles = action.payload.roles;
      state.userpreferences = action.payload.userpreferences;
      state.actionPrivs = action.payload.actionPrivs;
    },
    setPreferences: (state, action: PayloadAction<{ userpreferences: Preference[] }>) => {
      state.userpreferences = action.payload.userpreferences;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logOutThunk.fulfilled, () => {
        return initialState;
      })
      .addMatcher(authApi.endpoints.refreshUser.matchFulfilled, (state, { payload }) => {
        const decoded = jwtDecode<JWTUser>(payload.jwt);

        const user = JSON.parse(localStorage.getItem("user") ?? "{}");
        const authObj = {
          token: payload.jwt,
          userid: decoded.userId,
          userbaddress: decoded.baddress === "" ? AddressZero : decoded.baddress.toLowerCase(),
          org: user?.org,
          roles: user?.roles,
          userpreferences: user?.userpreferences,
          actionPrivs: new Array<string>(),
        };

        const actionPrivs = new Array<PrivilegeResponse>();
        authObj.roles.forEach((role: RoleResponse) => {
          actionPrivs.push(...(role.privs.filter((priv) => priv.type === "Action")?.flat() ?? []));
        });
        authObj.actionPrivs = actionPrivs.map(({ name }) => name);

        state = authObj;
        localStorage.setItem("jwt", payload.jwt);
      })
      // TODO: check values in action
      // TODO: reset states on logout
      .addMatcher(authApi.endpoints.refreshUser.matchRejected, (state) => {
        localStorage.removeItem("jwt");
        localStorage.removeItem("user");
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        state = initialState;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setAuth, setPreferences } = authSlice.actions;

export default authSlice.reducer;
