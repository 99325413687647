import { IProvider } from "@web3auth/base";
import { ethers, utils } from "ethers";

export default class EthereumRpc {
  private provider: IProvider;

  constructor(provider: IProvider) {
    this.provider = provider;
  }

  async getAccounts() {
    const ethersProvider = new ethers.providers.Web3Provider(this.provider);
    const signer = ethersProvider.getSigner();
    const address = await signer.getAddress();
    return address;
  }

  async getBalance() {
    const ethersProvider = new ethers.providers.Web3Provider(this.provider);
    const signer = ethersProvider.getSigner();
    const address = await signer.getAddress();
    const balance = utils.formatEther(await ethersProvider.getBalance(address));
    return balance;
  }

  async signMessage(message: any) {
    const ethersProvider = new ethers.providers.Web3Provider(this.provider);
    const signer = ethersProvider.getSigner();
    const signedMessage = await signer.signMessage(message);
    return signedMessage;
  }

  async getProvider() {
    const ethersProvider = new ethers.providers.Web3Provider(this.provider);
    return ethersProvider;
  }
  async getSigner() {
    const ethersProvider = new ethers.providers.Web3Provider(this.provider);
    const signer = await ethersProvider.getSigner();
    return signer;
  }
}
