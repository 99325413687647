import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Asset, AssetProduct, AssetProductResponse, AssetResponse } from "store/twin/types/types";

export const assetApi = createApi({
  reducerPath: "assetApi",
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: "/ps/ht/asset",
  }),
  tagTypes: ["asset", "assetProduct"],
  endpoints: (build) => ({
    getAssetByID: build.query<AssetResponse, number>({
      query: (assetId) => `/${assetId}`,
      providesTags: ["asset"],
    }),
    getAssetByAssetProductID: build.query<AssetResponse, number>({
      query: (assetProductId) => `/ap/${assetProductId}`,
      providesTags: ["asset"],
    }),
    getAssetsByFacilityID: build.query<AssetResponse[], number>({
      query: (facilityId) => `/facility/${facilityId}`,
      providesTags: ["asset"],
    }),
    getAssets: build.query<AssetResponse[], void>({
      query: () => "/",
      providesTags: ["asset"],
    }),
    addAsset: build.mutation<AssetResponse, Asset>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["asset"],
    }),
    updateAsset: build.mutation<void, Partial<Asset> & { ID: number }>({
      query: (body) => ({
        url: "/",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["asset"],
    }),
    deleteAsset: build.mutation<void, number>({
      query: (assetId) => ({
        url: `/${assetId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["asset"],
    }),
    getAssetProductByID: build.query<AssetProductResponse, number>({
      query: (assetProductId) => `/asset-product/${assetProductId}`,
      providesTags: ["assetProduct"],
    }),
    getAssetProductByAssetID: build.query<AssetProductResponse[], number>({
      query: (assetId) => `/asset-product/asset/${assetId}`,
      providesTags: ["assetProduct"],
    }),
    getAssetProducts: build.query<AssetProductResponse[], void>({
      query: () => "/asset-product",
      providesTags: ["assetProduct"],
    }),
    addAssetProduct: build.mutation<AssetProductResponse, AssetProduct>({
      query: (body) => ({
        url: "/asset-product",
        method: "POST",
        body,
      }),
      invalidatesTags: ["assetProduct"],
    }),
    updateAssetProduct: build.mutation<AssetProductResponse, AssetProduct & { ID: number }>({
      query: (body) => ({
        url: "/asset-product",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["asset", "assetProduct"],
    }),
    deleteAssetProduct: build.mutation<void, number>({
      query: (assetProductId) => ({
        url: `/asset-product/${assetProductId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["asset", "assetProduct"],
    }),
    linkAssetProductToAsset: build.mutation<void, { assetId: number; assetProductId: number }>({
      query: (input) => {
        return {
          url: `${input.assetId}/asset-product/${input.assetProductId}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["asset", "assetProduct"],
    }),
    unlinkAssetProductFromAsset: build.mutation<void, { assetId: number; assetProductId: number }>({
      query: (input) => {
        return {
          url: `${input.assetId}/asset-product/${input.assetProductId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["asset", "assetProduct"],
    }),
    createAndLinkAssetProductsToAsset: build.mutation<void, { assetId: number; assetProducts: AssetProduct[] }>({
      query: (input) => {
        return {
          url: `${input.assetId}/asset-products`,
          method: "PUT",
          body: input.assetProducts,
        };
      },
      invalidatesTags: ["asset", "assetProduct"],
    }),
    unlinkAssetProductsFromAsset: build.mutation<void, number>({
      query: (assetId) => {
        return {
          url: `${assetId}/asset-products`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["asset", "assetProduct"],
    }),
  }),
});

export const {
  useGetAssetByIDQuery,
  useGetAssetByAssetProductIDQuery,
  useGetAssetsByFacilityIDQuery,
  useGetAssetsQuery,
  useAddAssetMutation,
  useUpdateAssetMutation,
  useDeleteAssetMutation,
  useGetAssetProductByIDQuery,
  useLazyGetAssetProductByAssetIDQuery,
  useGetAssetProductsQuery,
  useAddAssetProductMutation,
  useUpdateAssetProductMutation,
  useDeleteAssetProductMutation,
  useLinkAssetProductToAssetMutation,
  useUnlinkAssetProductFromAssetMutation,
  useCreateAndLinkAssetProductsToAssetMutation,
  useUnlinkAssetProductsFromAssetMutation,
} = assetApi;
