// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #00240a;
}

.logo {
  margin-left: -36px;
  margin-top: -5px;
  width: 240px;
}

/* Antd Button css and colors through out the App */
.ant-btn {
  box-shadow: none;
}
.ant-btn:hover {
  border-color: #00240a !important;
  color: #00240a !important;
}
.ant-btn-primary {
  background-color: #00240a;
  border-color: #00240a;
  color: white;
}
.ant-btn-primary:hover {
  background-color: transparent !important;
  border-color: #00240a !important;
  color: #00240a !important;
}
.ant-btn-text {
  color: #00240a !important;
}
.ant-btn-text:hover {
  border-color: #f4f4f4 !important;
  color: #00240a !important;
}
.ant-btn-dangerous {
  border-color: #ec4d4f;
}
.ant-btn-dangerous:hover {
  border-color: #ec4d4f !important;
  color: #ec4d4f !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;;AAEA,mDAAmD;AACnD;EACE,gBAAgB;AAClB;AACA;EACE,gCAAgC;EAChC,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;AACd;AACA;EACE,wCAAwC;EACxC,gCAAgC;EAChC,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,gCAAgC;EAChC,yBAAyB;AAC3B;AACA;EACE,qBAAqB;AACvB;AACA;EACE,gCAAgC;EAChC,yBAAyB;AAC3B","sourcesContent":[":root {\n  --primary-color: #00240a;\n}\n\n.logo {\n  margin-left: -36px;\n  margin-top: -5px;\n  width: 240px;\n}\n\n/* Antd Button css and colors through out the App */\n.ant-btn {\n  box-shadow: none;\n}\n.ant-btn:hover {\n  border-color: #00240a !important;\n  color: #00240a !important;\n}\n.ant-btn-primary {\n  background-color: #00240a;\n  border-color: #00240a;\n  color: white;\n}\n.ant-btn-primary:hover {\n  background-color: transparent !important;\n  border-color: #00240a !important;\n  color: #00240a !important;\n}\n.ant-btn-text {\n  color: #00240a !important;\n}\n.ant-btn-text:hover {\n  border-color: #f4f4f4 !important;\n  color: #00240a !important;\n}\n.ant-btn-dangerous {\n  border-color: #ec4d4f;\n}\n.ant-btn-dangerous:hover {\n  border-color: #ec4d4f !important;\n  color: #ec4d4f !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
