import { CSSProperties, ReactNode } from "react";

export const getItem = (
  label: ReactNode,
  key: string,
  icon: ReactNode | null,
  privid: string,
  isDivider?: boolean | null,
  children?: any | null,
  style?: CSSProperties | null
) => {
  const menuItem = {
    key,
    icon,
    label,
    style,
    type: isDivider ? "divider" : "",
    children,
  };
  return {
    menuItem,
    privid,
  };
};
