/* eslint-disable @typescript-eslint/no-explicit-any */
import { Middleware } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import { authApi } from "store/auth/api/authapi";
import { logOutThunk } from "store/auth/slice/authSlice";

const ApiMiddleware401: Middleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (isRejectedWithValue(action) && action.payload?.originalStatus === 401) {
      try {
        const refreshResult = await dispatch(authApi.endpoints.refreshUser.initiate() as any).unwrap();

        if (!refreshResult) {
          dispatch(logOutThunk() as any);
        }
      } catch (error) {
        dispatch(logOutThunk() as any);
      }
    }

    return next(action);
  };

export default ApiMiddleware401;
