import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { NotificationResponse, Subscriber, SubscriberResponse } from "../types/types";

export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/ns/" }),
  tagTypes: ["notification"],
  endpoints: (build) => ({
    addDeviceToken: build.mutation<SubscriberResponse, Partial<Subscriber>>({
      query(body) {
        return {
          url: `subscriber`,
          method: "POST",
          headers: {
            "content-type": "text/plain",
          },
          body,
        };
      },
      invalidatesTags: ["notification"],
    }),
    getNotificationsByParam: build.query<NotificationResponse[], { subscriberid: string; notificationtype: string }>({
      query: (params) => {
        return {
          url: `notification`,
          params: params,
          headers: {
            "content-type": "text/plain",
          },
        };
      },
      providesTags: ["notification"],
    }),
  }),
});

export const {
  // Subscriber
  useAddDeviceTokenMutation,
  useGetNotificationsByParamQuery,
} = notificationApi;
