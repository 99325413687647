import {
  PinataListFilesResponse,
  PinataPinJsonToIPFSRequest,
  PinataPinJsonToIPFSResponse,
  PinataUpdateFileMetadataRequest,
} from "../types/types";
import { twinApi } from "store/twin/api/twinapi";

export const pinataApi = twinApi.injectEndpoints({
  endpoints: (build) => ({
    addPinIPFSJSON: build.mutation<PinataPinJsonToIPFSResponse, PinataPinJsonToIPFSRequest>({
      query(body) {
        return {
          url: `pinata/pin_json_to_ipfs`,
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body,
        };
      },
      invalidatesTags: ["Pinata"],
    }),
    updatePinMetadata: build.mutation<object, PinataUpdateFileMetadataRequest>({
      query(body) {
        return {
          url: `pinata/update_pin_metadata`,
          method: "PUT",
          headers: {
            "content-type": "application/json",
          },
          body,
        };
      },
      invalidatesTags: ["Pinata"],
    }),
    getPinnedDataByName: build.query<PinataListFilesResponse, unknown>({
      query: ({
        fileName,
        hashContains,
        pinStart,
        pinEnd,
        unpinStart,
        unpinEnd,
        pinSizeMin,
        pinSizeMax,
        status,
        pageLimit,
        pageOffset,
        metadata,
      }: {
        fileName: string;
        hashContains: string;
        pinStart: string;
        pinEnd: string;
        unpinStart: string;
        unpinEnd: string;
        pinSizeMin: string;
        pinSizeMax: string;
        status: string;
        pageLimit: string;
        pageOffset: string;
        metadata: string;
      }) => {
        const params = new URLSearchParams({
          ...(metadata && { "metadata[name]": fileName }),
          ...(hashContains && { hashContains }),
          ...(pinStart && { pinStart }),
          ...(pinEnd && { pinEnd }),
          ...(unpinStart && { unpinStart }),
          ...(unpinEnd && { unpinEnd }),
          ...(pinSizeMin && { pinSizeMin }),
          ...(pinSizeMax && { pinSizeMax }),
          ...(status && { status }),
          ...(pageLimit && { pageLimit }),
          ...(pageOffset && { pageOffset }),
        }).toString();

        return {
          url: `pinata/get_pinned_data_by_name?${params}`,
        };
      },
      providesTags: ["Pinata"],
    }),
  }),
});

export const {
  // Subscriber
  useAddPinIPFSJSONMutation,
  useUpdatePinMetadataMutation,
  useGetPinnedDataByNameQuery,
} = pinataApi;
