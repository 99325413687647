import { UserOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";

import Bell from "./DarkBellFilled.svg";
import { UserResponse } from "store/auth/types/types";
import { CSSProperties } from "react";

const WalletIcon = (props: {src: string; style?: CSSProperties}) => {
  return <Icon component={() => <img alt="" src={props?.src} />} {...props} />;
};

const BellIcon = (props: {src: string; style?: CSSProperties}) => {
  return <Icon component={() => <img alt="" src={props?.src ?? Bell} />} {...props} />;
};

const UserIcon = ({user, ...props}: {user: UserResponse }) => {
  return (
    !user?.profilepicture ? 
    <UserOutlined {...props} /> :
    <Icon
      component={() => (
        <img
          alt=""
          src={`data:image/png;base64,${Buffer.from(user?.profilepicture ?? [])?.toString("base64")}`}
        />
      )}
      {...props}
    />
  );
};

export { BellIcon, UserIcon, WalletIcon };
