/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ADAPTER_EVENTS, WALLET_ADAPTERS, UserInfo, WEB3AUTH_NETWORK } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

import type { IProvider } from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3Auth } from "@web3auth/modal";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { CHAIN_CONFIG } from "../config/chains";
import EthereumRpc from "./ethersRPC";
import { APP_NAME, WEB3AUTH_CLIENT_ID } from "config/config";
import { Signer } from "ethers";

export type Web3ContextType = {
  web3Auth?: Web3Auth;
  chain: string;
  privateKey: string;
  provider?: EthereumRpc;
  signer?: Signer;
  loggedIn: boolean;
  isLoading: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  loginWithSMS: (number: string) => Promise<void>;
  loginWithEmail: (email: string) => Promise<void>;
  loginWithGoogle: () => Promise<void>;
  getUserInfo: () => Promise<Partial<UserInfo> | undefined>;
  getAccounts: () => Promise<string | undefined>;
  getBalance: () => Promise<string | undefined>;
  signMessage: (msg: any) => Promise<string>;
  getPrivateKey: () => Promise<unknown>;
  getWalletInfo: () => Promise<
    | {
        id: string | undefined;
        method: string | undefined;
      }
    | undefined
  >;
};

export const Web3AuthContext = createContext({
  web3Auth: undefined,
  chain: "",
  privateKey: "",
  provider: undefined,
  signer: undefined,
  loggedIn: false,
  isLoading: false,
  login: async () => {},
  logout: async () => {},
  loginWithSMS: async () => {},
  loginWithEmail: async () => {},
  loginWithGoogle: async () => {},
  getUserInfo: async () => undefined,
  getAccounts: async () => undefined,
  getBalance: async () => undefined,
  signMessage: async () => "",
  getPrivateKey: async () => undefined,
  getWalletInfo: async () => undefined,
} as Web3ContextType);

export function useWeb3AuthContext() {
  return useContext(Web3AuthContext);
}

export const Web3AuthContextProvider = ({ children, chain }: { children: React.ReactNode; chain: string }) => {
  const [web3Auth, setWeb3Auth] = useState<Web3Auth>();
  const [provider, setProvider] = useState<EthereumRpc>();
  const [signer, setSigner] = useState<Signer>();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //TODO: Remove when there is no longer metamask connections
  const [privateKey, setPrivateKey] = useState("");

  const setWalletProvider = useCallback(async (web3authProvider: IProvider) => {
    const walletProvider = new EthereumRpc(web3authProvider);
    setProvider(walletProvider);
    const signer = await walletProvider.getSigner();
    setSigner(signer);
  }, []);

  useEffect(() => {
    async function init() {
      try {
        const currentChainConfig = CHAIN_CONFIG.get(chain === "production" ? "polygon" : "polygonAmoy");
        if (!currentChainConfig) return;
        setIsLoading(true);

        const privateKeyProvider = new EthereumPrivateKeyProvider({
          config: { chainConfig: currentChainConfig },
        });

        const web3AuthInstance = new Web3Auth({
          web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
          chainConfig: currentChainConfig,
          clientId: WEB3AUTH_CLIENT_ID,
          uiConfig: {
            appName: APP_NAME,
            useLogoLoader: true,
            logoDark: "http://dev.fuelfwd.io/fuelfwd-logo-light.png",
            logoLight: "http://dev.fuelfwd.io/fuelfwd-logo-dark.png",
          },
          enableLogging: true,
          privateKeyProvider,
        });

        const adapter = new OpenloginAdapter();
        web3AuthInstance.configureAdapter(adapter);
        setWeb3Auth(web3AuthInstance);
        await web3AuthInstance.init();
        if (web3AuthInstance.provider) setWalletProvider(web3AuthInstance.provider);
        subscribeAuthEvents(web3AuthInstance);

        const key = await web3AuthInstance.provider?.request({
          method: "eth_private_key", // use "private_key" for other non-evm chains
        });
        setPrivateKey(String(key));

        if (web3AuthInstance.connected) {
          setLoggedIn(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    init();
  }, [chain, setWalletProvider]);

  const subscribeAuthEvents = (web3auth: Web3Auth) => {
    web3auth.on(ADAPTER_EVENTS.CONNECTED, (data) => {
      setLoggedIn(true);
    });
    web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
      setLoggedIn(false);
    });
    web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
      if (web3auth.connected) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
      console.error(error);
    });
  };

  const loginWithSMS = async (number: string) => {
    if (!web3Auth) {
      console.warn("web3auth not initialized yet");
      return;
    }

    if (loggedIn) {
      await logout();
    }
    const web3authProvider = await web3Auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
      loginProvider: "sms_passwordless",
      extraLoginOptions: {
        login_hint: number ?? "+31-XXXXXXXX",
      },
    });
    if (web3authProvider) setWalletProvider(web3authProvider);
  };

  const loginWithEmail = async (email: string) => {
    if (!web3Auth) {
      console.warn("web3auth not initialized yet");
      return;
    }
    if (loggedIn) {
      await logout();
    }

    const web3authProvider = await web3Auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
      loginProvider: "email_passwordless",
      extraLoginOptions: {
        login_hint: email,
      },
    });
    if (web3authProvider) setWalletProvider(web3authProvider);
  };

  const loginWithGoogle = async () => {
    if (!web3Auth) {
      console.warn("web3auth not initialized yet");
      return;
    }

    if (loggedIn) {
      await logout();
    }
    const web3authProvider = await web3Auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
      loginProvider: "google",
    });
    if (web3authProvider) setWalletProvider(web3authProvider);
  };
  const login = async () => {
    if (!web3Auth) {
      console.warn("web3auth not initialized yet");
      return;
    }
    const localProvider = await web3Auth.connect();
    if (localProvider) setWalletProvider(localProvider);
  };

  const logout = async () => {
    if (!web3Auth) {
      console.warn("web3auth not initialized yet");
      return;
    }
    if (!loggedIn) return;
    await web3Auth.logout();
  };

  const getUserInfo = async () => {
    if (!web3Auth) {
      console.warn("web3auth not initialized yet");
      return;
    }
    return await web3Auth.getUserInfo();
  };

  const getAccounts = async () => {
    if (!provider) {
      console.warn("provider not initialized yet");
      return;
    }
    return await provider.getAccounts();
  };

  const getBalance = async () => {
    if (!provider) {
      console.warn("provider not initialized yet");
      return;
    }
    return await provider.getBalance();
  };

  const signMessage = async (message: any) => {
    if (!provider) {
      console.warn("provider not initialized yet");
      throw new Error("provider not initialized yet");
    }
    return await provider.signMessage(message);
  };

  const getPrivateKey = async () => {
    if (!web3Auth) {
      console.warn("web3auth not initialized yet");
      return;
    }
    const key = await web3Auth.provider?.request({
      method: "eth_private_key", // use "private_key" for other non-evm chains
    });
    return key;
  };

  const getWalletInfo = async () => {
    if (!web3Auth) {
      console.warn("web3auth not initialized yet");
      return;
    }
    const userInfo = await web3Auth.getUserInfo();
    const id = userInfo.verifierId;
    const array = userInfo.aggregateVerifier?.split("-");
    const method = array?.find((element) => ["sms", "email", "google"].includes(element));
    return { id, method };
  };

  const contextProvider = {
    web3Auth,
    chain,
    privateKey,
    provider,
    signer,
    loggedIn,
    isLoading,
    login,
    logout,
    loginWithSMS,
    loginWithEmail,
    loginWithGoogle,
    getUserInfo,
    getAccounts,
    getBalance,
    signMessage,
    getPrivateKey,
    getWalletInfo,
  };

  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>;
};
