import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "store/store";
import { Pagination, QueryParameters } from "store/types/types";
import { AssetInfo, AssetResponse, FetchDocResponse, UserAssetResponse } from "../types/types";
import { DocumentType, DocumentTypeResponse } from "store/twin/types/types";

class File extends Blob {
  name?: string;
  constructor(blobParts: BlobPart[], options?: BlobPropertyBag, name?: string) {
    super(blobParts, options);
    this.name = name;
  }
}

export const vaultApi = createApi({
  reducerPath: "vaultApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/vault/",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Vault"],
  endpoints: (build) => ({
    getVaultDocuments: build.query<UserAssetResponse[], void>({
      query: () => "assets/list",
      providesTags: ["Vault"],
    }),

    getVaultDocumentsByPagination: build.query<
      Pagination<AssetResponse>,
      QueryParameters<AssetResponse, AssetResponse> & { docids: string }
    >({
      query: (params) => ({ url: "assets/pagination", params }),
      providesTags: ["Vault"],
    }),

    // downloadDocument: build.query<void, string>({
    //   query: (dochash) => `assets/${dochash}`,
    //   providesTags: ["Vault"],
    // }),

    getDocumentInfo: build.query<AssetInfo, string>({
      query: (docid) => `assets/${docid}/info`,
      providesTags: ["Vault"],
    }),

    getDocumentsInfo: build.query<AssetResponse[], string[]>({
      query: (assetIds) => ({
        url: `assets/assetIds`,
        params: { assetIds },
      }),
      providesTags: ["Vault"],
    }),

    fetchDocument: build.query<FetchDocResponse, string>({
      query: (docId) => ({
        url: `assets/${docId}`,
        responseHandler: async (response) => {
          const fileProperties = {
            fileName: response.headers.get("Filename") ?? "",
            fileType: response.headers.get("Content-Type") ?? "",
            fileSize: response.headers.get("Content-Length") ?? "",
          };

          const byteArray = await response.arrayBuffer();
          const blob = new File([byteArray], { type: fileProperties.fileType }, fileProperties.fileName);
          return {
            fileProperties,
            blob,
          };
        },
      }),
      providesTags: ["Vault"],
    }),

    uploadFile: build.mutation<{ asset: string; assetid: string }, FormData>({
      query(body) {
        return {
          url: `assets/upload`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Vault"],
    }),

    retriggerPipeline: build.mutation<void, { document_id: string; owner_id: string }>({
      query(docInfo) {
        return {
          url: `assets/retriggerPipeline`,
          method: "POST",
          body: {
            // eslint-disable-next-line camelcase
            document_id: docInfo.document_id,
            // eslint-disable-next-line camelcase
            owner_id: docInfo.owner_id,
          },
        };
      },
      invalidatesTags: ["Vault"],
    }),

    cloneAssets: build.mutation<AssetResponse, string>({
      query: (assetId) => ({
        url: `assets/clone/${assetId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Vault"],
    }),

    getAuthInfo: build.query<UserAssetResponse[], string>({
      query: (assetid) => `auth/${assetid}`,
      providesTags: ["Vault"],
    }),
    grantOrgAccess: build.mutation<void, { orgId: string; assetId: string; accesstype: string }>({
      query(input) {
        return {
          url: `auth/grant/org/${input.orgId}/${input.assetId}/${input.accesstype}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Vault"],
    }),
    updateVisibility: build.mutation<void, { assetId: string; documentvisibility: string }>({
      query(input) {
        return {
          url: `auth/${input.assetId}/${input.documentvisibility}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Vault"],
    }),
    liftOrgAccess: build.mutation<void, { orgId: string; assetId: string }>({
      query(input) {
        return {
          url: `auth/lift/org/${input.orgId}/${input.assetId}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Vault"],
    }),
    getDocTypes: build.query<DocumentTypeResponse[], void>({
      query: () => "doctype",
      providesTags: ["Vault"],
    }),
    getDocTypesByPagination: build.query<Pagination<DocumentTypeResponse>, QueryParameters<DocumentTypeResponse>>({
      query: (params) => ({
        url: `doctype/pagination`,
        params,
      }),
      providesTags: ["Vault"],
    }),
    getDocTypeById: build.query<DocumentTypeResponse, string>({
      query: (id) => ({
        url: `doctype/${id}`,
      }),
      providesTags: ["Vault"],
    }),
    addDocType: build.mutation<DocumentTypeResponse, DocumentType>({
      query(body) {
        return {
          url: "doctype",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Vault"],
    }),
    updateDocType: build.mutation<DocumentTypeResponse, Partial<DocumentType> & { ID: string }>({
      query(body) {
        return {
          url: "doctype",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Vault"],
    }),
    deleteDocType: build.mutation<void, string>({
      query(id) {
        return {
          url: `doctype/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Vault"],
    }),
  }),
});

export const {
  // useDownloadDocumentQuery,
  useGetDocumentInfoQuery,
  useGetDocumentsInfoQuery,
  useFetchDocumentQuery,
  useLazyFetchDocumentQuery,
  useGetVaultDocumentsByPaginationQuery,
  useGetAuthInfoQuery,
  useLazyGetAuthInfoQuery,
  useGetVaultDocumentsQuery,
  useUploadFileMutation,
  useRetriggerPipelineMutation,
  useUpdateVisibilityMutation,
  useGrantOrgAccessMutation,
  useLiftOrgAccessMutation,
  useCloneAssetsMutation,

  // DocTypes
  useGetDocTypesQuery,
  useGetDocTypesByPaginationQuery,
  useLazyGetDocTypeByIdQuery,
  useAddDocTypeMutation,
  useDeleteDocTypeMutation,
  useUpdateDocTypeMutation,
} = vaultApi;
