import { HydroTwinArtifact } from "contractArtifacts/HydroTwinArtifact";
import { MarketPlaceArtifact } from "contractArtifacts/MarketPlaceArtifact";
import { OrganizationArtifact } from "contractArtifacts/OrganizationArtifact";
import { OrganizationFactoryArtifact } from "contractArtifacts/OrganizationFactoryArtifact";

const {
  REACT_APP_NAME,
  REACT_APP_ENV_NAME,
  REACT_APP_FIREBASE_APIKEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGEBUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_VAPID_KEY,
  REACT_APP_PINATA_API_KEY,
  REACT_APP_HYDROTWIN_CONTRACT_ADDRESS,
  REACT_APP_MARKETPLACE_CONTRACT_ADDRESS,
  REACT_APP_ORGANIZATION_FACTORY_CONTRACT_ADDRESS,
  REACT_APP_ETH_NODE_ADDRESS,
  REACT_APP_WEB3AUTH_CLIENT_ID,
  NODE_ENV,
  REACT_APP_FOURLITE_CONFIG,
  // eslint-disable-next-line no-undef
} = process.env;

if (!REACT_APP_PINATA_API_KEY) {
  console.warn("[WARNING] REACT_APP_PINATA_API_KEY is empty");
}

if (!REACT_APP_ETH_NODE_ADDRESS) {
  console.warn("[WARNING] REACT_APP_ETH_NODE_ADDRESS is empty");
}

const APP_NAME = REACT_APP_NAME ?? "FuelFWD";
const ENV_NAME = REACT_APP_ENV_NAME ?? "dev";

const YOUR_HYDROTWIN_TOKENIZATION_CONTRACT_ADDRESS = REACT_APP_HYDROTWIN_CONTRACT_ADDRESS as `0x${string}`;
const YOUR_HYDROTWIN_TOKENIZATION_CONTRACT_ADDRESS_ABI = HydroTwinArtifact.abi;

const YOUR_HYDROTWIN_ORGANIZATION_CONTRACT_ADDRESS_ABI = OrganizationArtifact.abi;

const YOUR_HYDROTWIN_ORGANIZATION_FACTORY_CONTRACT_ADDRESS =
  REACT_APP_ORGANIZATION_FACTORY_CONTRACT_ADDRESS as `0x${string}`;
const YOUR_HYDROTWIN_ORGANIZATION_FACTORY_CONTRACT_ADDRESS_ABI = OrganizationFactoryArtifact.abi;

const YOUR_MARKETPLACE_CONTRACT_ADDRESS = REACT_APP_MARKETPLACE_CONTRACT_ADDRESS as `0x${string}`;
const YOUR_MARKETPLACE_CONTRACT_ADDRESS_ABI = MarketPlaceArtifact.abi;

const ETH_NODE_ADDRESS = REACT_APP_ETH_NODE_ADDRESS ?? "";
const WEB3AUTH_CLIENT_ID = REACT_APP_WEB3AUTH_CLIENT_ID ?? "";

const FIREBASE_APIKEY = REACT_APP_FIREBASE_APIKEY;
const FIREBASE_AUTH_DOMAIN = REACT_APP_FIREBASE_AUTH_DOMAIN;
const FIREBASE_PROJECT_ID = REACT_APP_FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET = REACT_APP_FIREBASE_STORAGEBUCKET;
const FIREBASE_MESSAGING_SENDER_ID = REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_APP_ID = REACT_APP_FIREBASE_APP_ID;
const FIREBASE_MEASUREMENT_ID = REACT_APP_FIREBASE_MEASUREMENT_ID;
const FIREBASE_VAPID_KEY = REACT_APP_FIREBASE_VAPID_KEY;
const PINATA_API_KEY = REACT_APP_PINATA_API_KEY;
const FOURLITE_CONFIG = REACT_APP_FOURLITE_CONFIG;

export {
  APP_NAME,
  ENV_NAME,
  FIREBASE_APIKEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_VAPID_KEY,
  FOURLITE_CONFIG,
  NODE_ENV,
  PINATA_API_KEY,
  YOUR_HYDROTWIN_ORGANIZATION_CONTRACT_ADDRESS_ABI,
  YOUR_HYDROTWIN_ORGANIZATION_FACTORY_CONTRACT_ADDRESS,
  YOUR_HYDROTWIN_ORGANIZATION_FACTORY_CONTRACT_ADDRESS_ABI,
  YOUR_HYDROTWIN_TOKENIZATION_CONTRACT_ADDRESS,
  YOUR_HYDROTWIN_TOKENIZATION_CONTRACT_ADDRESS_ABI,
  YOUR_MARKETPLACE_CONTRACT_ADDRESS,
  YOUR_MARKETPLACE_CONTRACT_ADDRESS_ABI,
  ETH_NODE_ADDRESS,
  WEB3AUTH_CLIENT_ID,
};
