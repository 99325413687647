import { QueryParameters } from "store/types/types";
import { PrivilegeResponse, User, UserPagination, UserResponse } from "../types/types";
import { authApi } from "./authapi";

export const userApi = authApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<UserPagination, void>({
      query: () => "user/",
      providesTags: ["Users"],
    }),
    getUser: build.query<UserResponse, string>({
      query: (id) => `user/${id}`,
      providesTags: ["Users"],
    }),
    getUsersByParam: build.query<UserPagination, QueryParameters<UserResponse>>({
      query: (params) => {
        return {
          url: "user/",
          params,
        };
      },
      providesTags: ["Users"],
    }),
    getUserURLPrivs: build.query<{ privs: PrivilegeResponse[] }, string>({
      query: (userId) => `user/${userId}/url/privs/frontendURL`,
      providesTags: ["Users"],
    }),
    linkOrgToUser: build.mutation<{ response: string }, { uid: string; orgId: string }>({
      query: (body) => {
        return {
          url: `user/linkorg`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    removeOrgFromUser: build.mutation<{ response: string }, { uid: string }>({
      query: (params) => {
        return {
          url: "user/org",
          method: "DELETE",
          params,
        };
      },
      invalidatesTags: ["Users"],
    }),
    addRoleToUser: build.mutation<{ response: string }, { uid: string; roleName: string }>({
      query: (body) => {
        return {
          url: "user/addrole/",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    removeRoleToUser: build.mutation<{ response: string }, { uid: string; roleName: string }>({
      query: (params) => {
        return {
          url: "user/role/",
          method: "DELETE",
          params,
        };
      },
      invalidatesTags: ["Users"],
    }),
    updateUser: build.mutation<{ response: string }, Omit<User, "roles"> & { _id: string; roles: string[] }>({
      query(body) {
        return {
          url: "user/",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    updateUserWithPicture: build.mutation<{ response: string }, FormData>({
      query(body) {
        return {
          url: "user/",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    deleteUser: build.mutation<{ result: UserResponse }, string>({
      query(id) {
        return {
          url: `user/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUsersQuery,
  useGetUsersByParamQuery,
  useLazyGetUsersByParamQuery,
  useGetUserURLPrivsQuery,
  useLazyGetUserURLPrivsQuery,
  useLinkOrgToUserMutation,
  useRemoveOrgFromUserMutation,
  useAddRoleToUserMutation,
  useRemoveRoleToUserMutation,
  useUpdateUserMutation,
  useUpdateUserWithPictureMutation,
  useDeleteUserMutation,
} = userApi;
