import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TwinExtendedRespose } from "../types/types";

export type TwinPagination = {
  twins: TwinExtendedRespose[];
  selectedTwins: TwinExtendedRespose[];
  pagination: {
    isRequested: boolean;
    twinsFilter: string;
    limit: number;
    page: number;
    hasNext: boolean;
    hasPrev: boolean;
    totalPages: number;
    order: "ascend" | "descend";
    sortby: string;
    filter?: string;
    searchFilter: string;
  };
};

const initialState: TwinPagination = {
  twins: [],
  selectedTwins: [],
  pagination: {
    isRequested: false,
    twinsFilter: "all",
    limit: 8,
    page: 1,
    hasNext: false,
    hasPrev: false,
    totalPages: 0,
    order: "descend",
    sortby: "created_at",
    filter: "",
    searchFilter: "",
  },
};

export const twinSlice = createSlice({
  name: "twin",
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<TwinPagination["pagination"]>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
        hasNext: state.pagination.page < action.payload.totalPages,
        hasPrev: state.pagination.page > 1,
      };
    },
    setIsRequested: (state, action: PayloadAction<boolean>) => {
      state.pagination.isRequested = action.payload;
    },
    setPaginationFilter: (state, action: PayloadAction<string>) => {
      state.pagination.filter = action.payload;
    },
    setPaginationSearchFilter: (state, action: PayloadAction<string>) => {
      state.pagination.searchFilter = action.payload;
    },
    setTwinsFilter: (state, action: PayloadAction<string>) => {
      state.pagination.twinsFilter = action.payload;
      state.pagination.page = 1;
    },
    selectTwin: (state, action: PayloadAction<TwinExtendedRespose>) => {
      state.selectedTwins = [...state.selectedTwins, action.payload];
    },
    selectOnlyTwin: (state, action: PayloadAction<TwinExtendedRespose>) => {
      state.selectedTwins = [action.payload];
    },
    deselectTwin: (state, action: PayloadAction<TwinExtendedRespose>) => {
      state.selectedTwins = state.selectedTwins.filter((prod) => prod.ID !== action.payload.ID);
    },
    clearSelection: (state) => {
      state.selectedTwins = [];
    },
    setTwin: (state, action: PayloadAction<TwinExtendedRespose>) => {
      state.twins = [...state.twins, action.payload];
    },
    removeTwin: (state, action: PayloadAction<number>) => {
      state.twins = state.twins.filter((data) => data.ID !== action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTwin,
  removeTwin,
  clearSelection,
  selectTwin,
  selectOnlyTwin,
  deselectTwin,
  setPagination,
  setPaginationFilter,
  setPaginationSearchFilter,
  setTwinsFilter,
  setIsRequested,
} = twinSlice.actions;

export default twinSlice.reducer;
