import DarkBell from "icons/DarkBellFilled.svg";
import LightBell from "icons/LightBellFilled.svg";

import DarkConnectedWallet from "icons/ConnectedWallet.svg";
import DarkNotConnectedWallet from "icons/NotConnectedWallet.svg";

import LightConnectedWallet from "icons/LightConnectedWallet.svg";
import LightNotConnectedWallet from "icons/LightNotConnectedWallet.svg";

export const colorToTheme: {
  [key: string]: "light" | "dark";
} = {
  "#ffffff": "light",
  "#00240A": "dark",
} as const;

export const themeStyles = {
  light: {
    background: "white",
    color: "#4A5161",
  },
  dark: {
    background: "#00240A",
    color: "white",
  },
} as const;
export const themeLogos = {
  light: "/hydrotwin-logo.svg",
  dark: "/hydrotwin-logo-dark.svg",
} as const;
export const themeBell = {
  light: DarkBell,
  dark: LightBell,
} as const;
export const themeWallet = {
  light: {
    connected: { icon: DarkConnectedWallet, style: { color: "#2E723D" } },
    notConnected: { icon: DarkNotConnectedWallet, style: { color: "#C4C4C4" } },
  },
  dark: {
    connected: { icon: LightConnectedWallet, style: { color: "#EBFD5D" } },
    notConnected: { icon: LightNotConnectedWallet, style: { color: "#C4C4C4" }  },
  },
} as const;
