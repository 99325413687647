import "./index.css";

import { ConfigProvider } from "antd";
import { ENV_NAME } from "config/config";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Web3AuthContextProvider } from "web3Auth";
import App from "./App";
import store from "./store/store";

const hydrotwinTheme = {
  colorPrimary: "#00240A",
  colorLink: "#2E723D",
  colorError: "#EC4D4F",
  colorWarning: "#FFF962",
  colorSuccess: "#52c41a",
  colorInfo: "#1890ff",
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <Web3AuthContextProvider chain={ENV_NAME}>
        <ConfigProvider
          theme={{
            token: hydrotwinTheme,
          }}
          componentSize="large"
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ConfigProvider>
      </Web3AuthContextProvider>
    </Provider>
  </React.StrictMode>
);
