import { CHAIN_NAMESPACES } from "@web3auth/base";
import type { CustomChainConfig } from "@web3auth/base";
import { ETH_NODE_ADDRESS } from "config/config";

export const CHAIN_CONFIG = new Map<string, CustomChainConfig>([
  [
    "polygon",
    {
      chainNamespace: CHAIN_NAMESPACES.EIP155,
      rpcTarget: ETH_NODE_ADDRESS,
      chainId: "0x89",
      displayName: "Polygon Mainnet",
    },
  ],
  [
    "polygonAmoy",
    {
      chainNamespace: CHAIN_NAMESPACES.EIP155,
      rpcTarget: ETH_NODE_ADDRESS,
      chainId: "0x13882",
      displayName: "Polygon Amoy",
    },
  ],
]);
