import { NotFound } from "features/notFoundPage";
import { Redirect, Route, Switch, type RouteProps } from "react-router-dom";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";
import { useMemo } from "react";
import { useAppSelector } from "hooks/hooks";
import { useGetUserURLPrivsQuery } from "store/auth/api/userapi";
import { Flex, Spin } from "antd";

export const Routes = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const { userid } = useAppSelector((state) => state.auth);

  const { data: privatePrivs = { privs: [] }, isLoading } = useGetUserURLPrivsQuery(userid, {
    skip: userid === "",
  });

  const allRoutes = useMemo(() => {
    const privatePrivsNames = privatePrivs.privs.map(({ name }) => name);

    const filteredProtectedRoutes = protectedRoutes.filter(
      ({ path }) => path && typeof path === "string" && privatePrivsNames.includes(path.toLowerCase())
    );

    const privateRedirectPath = privatePrivsNames.includes("/dashboard")
      ? "/dashboard"
      : privatePrivsNames.includes("/org")
      ? "/org"
      : "/profile";

    const commonRoutes: RouteProps[] = [
      {
        path: "/",
        render: () => <Redirect to={isLoggedIn ? privateRedirectPath : "/login"} />,
        exact: true,
      },
      {
        path: "/login",
        render: () => <Redirect to={isLoggedIn ? privateRedirectPath : "/login"} />,
        exact: true,
      },
      {
        path: "*",
        render: () => (isLoggedIn ? <NotFound /> : <Redirect to={"/login"} />),
        exact: true,
      },
    ];
    return isLoggedIn ? [...filteredProtectedRoutes, ...commonRoutes] : [...publicRoutes, ...commonRoutes];
  }, [privatePrivs.privs, isLoggedIn]);

  if (isLoading) {
    return (
      <Flex align="center" gap="middle" style={{ justifyContent: "center", height: "34rem" }}>
        <Spin spinning={true} size="large" />
      </Flex>
    );
  }

  return (
    <Switch>
      {allRoutes.map(({ path, render, exact }) => {
        return <Route key={`${path}`} path={path} exact={exact} render={render} />;
      })}
    </Switch>
  );
};
