import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  Dynamicfield,
  DynamicfieldResponse,
  Product,
  ProductResponse,
  Unit,
  UnitResponse,
} from "store/twin/types/types";
import type { Pagination, QueryParameters } from "store/types/types";

export const referenceApi = createApi({
  reducerPath: "referenceApi",
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: "/ps/references/",
  }),
  tagTypes: ["Product", "Unit"],
  endpoints: (build) => ({
    getProducts: build.query<ProductResponse[], void>({
      query: () => "products",
      providesTags: ["Product"],
    }),
    getProduct: build.query<ProductResponse, string>({
      query: (name) => `products/${name}`,
      providesTags: ["Product"],
    }),
    getProductsByNames: build.query<ProductResponse[], string[]>({
      query: (body) => {
        return {
          url: `products/byNames`,
          method: "POST",
          body,
        };
      },
      providesTags: ["Product"],
    }),
    getProductsByPagination: build.query<Pagination<ProductResponse>, QueryParameters<ProductResponse>>({
      query: (params) => {
        return {
          url: `products/pagination`,
          params,
        };
      },
      providesTags: ["Product"],
    }),
    getProductDynamicFieldsById: build.query<DynamicfieldResponse[], number>({
      query: (id) => `products/dynamic-fields/${id}`,
      providesTags: ["Product"],
    }),
    addProduct: build.mutation<ProductResponse, Product>({
      query(body) {
        return {
          url: "products",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Product"],
    }),
    addProductDynamicFields: build.mutation<
      DynamicfieldResponse[],
      { productId: number; dynamicFields: Partial<Dynamicfield>[] }
    >({
      query: ({ productId, dynamicFields }) => {
        return {
          url: `products/dynamic-fields/${productId}`,
          method: "POST",
          body: { dynamicFields },
        };
      },
      invalidatesTags: ["Product"],
    }),
    updateProduct: build.mutation<ProductResponse, Partial<Product> & { ID: number }>({
      query(body) {
        return {
          url: "products",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Product"],
    }),
    linkProductDynamicFields: build.mutation<void, { productId: number; dynamicFieldIds: number[] }>({
      query: ({ productId, dynamicFieldIds }) => {
        return {
          url: `products/dynamic-fields/${productId}`,
          method: "PUT",
          body: { dynamicFieldIds },
        };
      },
      invalidatesTags: ["Product"],
    }),
    deleteProduct: build.mutation<void, number>({
      query(id) {
        return {
          url: `products/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Product"],
    }),
    //List of Units
    getUnits: build.query<UnitResponse[], void>({
      query: () => "units",
      providesTags: ["Unit"],
    }),
    // Get specific Unit
    getUnit: build.query<UnitResponse, number>({
      query: (id) => `units/${id}`,
      providesTags: ["Unit"],
    }),
    getUnitsByPagination: build.query<Pagination<UnitResponse>, QueryParameters<UnitResponse>>({
      query: (params) => ({
        url: `units/pagination`,
        params,
      }),
      providesTags: ["Unit"],
    }),
    addUnit: build.mutation<UnitResponse, Unit>({
      query(body) {
        return {
          url: `units`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Unit"],
    }),
    updateUnit: build.mutation<UnitResponse, Partial<Unit> & { ID: number }>({
      query(body) {
        return {
          url: `units`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Unit"],
    }),
    deleteUnit: build.mutation<void, number>({
      query(id) {
        return {
          url: `units/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Unit"],
    }),
  }),
});

export const {
  // Product
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useGetProductQuery,
  useLazyGetProductQuery,
  useGetProductsByNamesQuery,
  useGetProductsByPaginationQuery,
  useAddProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,

  //Units
  useAddUnitMutation,
  useDeleteUnitMutation,
  useGetUnitQuery,
  useGetUnitsQuery,
  useGetUnitsByPaginationQuery,
  useUpdateUnitMutation,
} = referenceApi;
